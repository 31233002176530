import React from 'react';
import style from './style.css';
const Appdefs = window.cccisd.appDefs;
const Footer = props => {
    const copyrightYear = props.copyrightYear || Appdefs?.app?.footer?.copyrightYear || new Date().getFullYear();
    return (
        <div className={style.footer}>
            <div className={style.section}>
                Software developed by <a href="//www.3cisd.com?r=pcanc">3C Institute</a>. &copy; {copyrightYear}{' '}
                Positive Childhood Alliance North Carolina, All rights reserved.
            </div>
            <div className={style.section}>
                <strong>A chapter of Prevent Child Abuse America</strong> &bull;{' '}
                <a href="//www.3cisd.com/?r=pcanc/#ccc-lets-talk" rel="noopener noreferrer" target="_blank">
                    Contact Us
                </a>{' '}
                &bull;{' '}
                <a href="//www.3cisd.com/terms-of-use/?r=pcanc" rel="noopener noreferrer" target="_blank">
                    Terms of Use
                </a>{' '}
                &bull;{' '}
                <a href="//www.3cisd.com/privacy-policy/?r=pcanc" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                </a>
            </div>
        </div>
    );
};

export default Footer;
